<template>
  <a-modal v-model:visible="localShowModal" @cancel="handleCancel" width="700px">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('modal-actions.update')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
    </template>
    <h2 class="px-1">{{ company.name || (userTypePrefix ? $t(`modal-titles.new-${userTypePrefix}`) : '') }}</h2>
    <a-row class="data-container">
      <a-col :xs="24" :md="12">
        <a-row>
          <a-col :span="24" class="title mb-1 px-1">{{ $t('basic-data') }}</a-col>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t('company') }}</label>
                <a-input v-model:value="localCompany.name" size="large"></a-input>
                <div v-if="isCompanyNameDirty">
                  <div v-if="isCompanyNameRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1 ">
              <div class="inp-wrap">
                <label>{{ $t('ico') }}</label>
                <a-input v-model:value="localCompany.ico" size="large"></a-input>
                <div v-if="isCompanyIcoDirty">
                  <div v-if="isCompanyIcoRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class=" inp-wrap">
                <label>{{ $t('dic') }}</label>
                <a-input v-model:value="localCompany.dic" size="large"></a-input>
                <div v-if="isCompanyDicDirty">
                  <div v-if="isCompanyDicRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
          </a-col>

        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="second">
        <a-row>
          <a-col :span="24" class="title mb-1 px-1">{{ $t('residence') }}</a-col>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t('street') }}</label>
                <a-input v-model:value="localCompany.street" size="large"></a-input>
                <div v-if="isCompanyStreetDirty">
                  <div v-if="isCompanyStreetRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t('district') }}</label>
                <a-input v-model:value="localCompany.district" size="large"></a-input>
                <div v-if="isCompanyDistrictDirty">
                  <div v-if="isCompanyDistrictRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t('zip') }}</label>
                <a-input v-model:value="localCompany.zip_code" size="large"></a-input>
                <div v-if="isCompanyZipDirty">
                  <div v-if="isCompanyZipRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="mt-2">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="title">{{ $t('account-number') }}</div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <a-input v-model:value="localCompany.account" size="large"></a-input>
                <div v-if="isCompanyAccountDirty">
                  <div v-if="isCompanyAccountRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="mt-2">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="title">{{ $t('mailing-address') }}</div>
            <div class="data mt-1">
              <a-radio-group v-model:value="localCompany.mailing_address_input" class="radio-group">
                <a-radio :value="false">{{$t('identical')}}</a-radio>
                <a-radio :value="true">{{$t('separated')}}</a-radio>
              </a-radio-group>
            </div>
            <div v-if="localCompany.mailing_address_input" class="mt-1">
              <div class="data">
                <div class="inp-wrap">
                  <label>{{ $t('street') }}</label>
                  <a-input v-model:value="localCompany.mailing_address.street" size="large"></a-input>
                  <div v-if="isCompanyMailingStreetDirty">
                    <div v-if="isCompanyMailingStreetRequired" class="error-message">{{$t('field-required')}}</div>
                  </div>
                </div>
              </div>
              <div class="data mt-1">
                <div class="inp-wrap">
                  <label>{{ $t('district') }}</label>
                  <a-input v-model:value="localCompany.mailing_address.district" size="large"></a-input>
                  <div v-if="isCompanyMailingDistrictDirty">
                    <div v-if="isCompanyMailingDistrictRequired" class="error-message">{{$t('field-required')}}</div>
                  </div>
                </div>
              </div>
              <div class="data mt-1">
                <div class="inp-wrap">
                  <label>{{ $t('zip') }}</label>
                  <a-input v-model:value="localCompany.mailing_address.zip_code" size="large"></a-input>
                  <div v-if="isCompanyMailingZipDirty">
                    <div v-if="isCompanyMailingZipRequired" class="error-message">{{$t('field-required')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  props: {
    userTypePrefix: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: () => {},
    }
  },
  components: {
  },
  data() {
    return {
      localShowModal: this.showModal,
      localCompany: Object.assign({}, this.company)
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    company(newVal) {
      this.localCompany = Object.assign({}, newVal);
    },
    'localCompany.mailing_address_input'(v) {
      if (v && !this.localCompany.mailing_address) {
        this.localCompany.mailing_address = {
          street: '',
          district: '',
          zip_code: ''
        }
        console.log(this.localCompany.mailing_address)
      }
    }
  },
  methods: {
    handleOk() {
      this.v$.$touch();
      if (this.localCompany.mailing_address_input) {
        if (this.isCompanyNameRequired
            || this.isCompanyIcoRequired
            || this.isCompanyDicRequired
            || this.isCompanyAccountRequired
            || this.isCompanyStreetRequired
            || this.isCompanyDistrictRequired
            || this.isCompanyZipRequired
            || this.isCompanyMailingZipRequired
            || this.isCompanyMailingDistrictRequired
            || this.isCompanyMailingStreetRequired
        ){
          return;
        }
      } else {
        if (this.isCompanyNameRequired
            || this.isCompanyIcoRequired
            || this.isCompanyDicRequired
            || this.isCompanyAccountRequired
            || this.isCompanyStreetRequired
            || this.isCompanyDistrictRequired
            || this.isCompanyZipRequired){
          return;
        }
      }

      this.$emit('update', this.localCompany)
    },
    handleCancel() {
      this.v$.$reset();
      this.$emit('close')
    },
  }


}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
@import "~@/assets/scss/components/first-tab.scss";
</style>