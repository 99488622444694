<template>
  <div class="mb-2 tab-head mt-3">
    <a-input v-model:value="searchValue" class="search-input" :placeholder="$t('search')">
      <template #prefix>
        <search-outlined class="search-icon" />
      </template>
    </a-input>
    <a-button @click="showAddUnitHandler" size="large" type="primary"><plus-outlined />{{ $t('add') }}</a-button>
  </div>

  <a-table
      :columns="columns"
      :data-source="filteredData"
      :pagination="{ ...pagination, locale: paginationLocale }"
      :customRow="customRow"
      :loading="loading"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'action'">
        <a-dropdown trigger="click">
          <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
            <more-outlined />
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                  @click="editHandler(selectedUnit)"
                  key="3"
              >
                <edit-outlined />
                <span>{{ $t('edit') }}</span>
              </a-menu-item>
              <a-menu-item
                  @click="RFCHandler"
                  key="1"
              >
                <delete-outlined />
                <span>{{ $t('modal-titles.delete-f-company') }}</span>
              </a-menu-item>
              <a-menu-item
                  @click="DFSHandler"
                  key="2"><delete-outlined
              />
                {{ $t('delete-f-system') }}
              </a-menu-item>
            </a-menu>
          </template>

        </a-dropdown>
      </template>
      <template v-if="column.key === 'all'">
        <a-card :title="$t('user')" class="table-card">
          <p class="user-card-row">
              <span>
                {{$t('resp-unit-id')}}
              </span>
            <span>{{record.unit_id}}</span>
          </p>
          <p class="user-card-row">
              <span>
                {{$t('number')}}
              </span>
            <span>{{record.number}}</span>
          </p>
          <p class="user-card-row">
              <span>
                {{$t('sim-card')}}
              </span>
            <span>{{record.sim}}</span>
          </p>
          <p class="user-card-row">
              <span>
                {{$t('rn-auto')}}
              </span>
            <span>{{record.rn}}</span>
          </p>
          <p class="user-card-row">
              <span>
                {{$t('gps-location')}}
              </span>
            <span>{{record.gps}}</span>
          </p>
          <p class="user-card-row">
              <span>
                {{$t('location-address')}}
              </span>
            <span>{{record.address}}</span>
          </p>
          <p class="user-card-row">
              <span>
                {{$t('actions')}}
              </span>
            <span>
                <a-dropdown trigger="click">
                  <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
                    <more-outlined />
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                          @click="editHandler(selectedUnit)"
                          key="3"
                      >
                        <edit-outlined />
                        <span>{{ $t('edit') }}</span>
                      </a-menu-item>
                      <a-menu-item
                          @click="RFCHandler"
                          key="1"
                      >
                        <delete-outlined />
                        <span>{{ $t('modal-titles.delete-f-company') }}</span>
                      </a-menu-item>
                      <a-menu-item
                          @click="DFSHandler"
                          key="2"><delete-outlined
                      />
                        {{ $t('delete-f-system') }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </span>
          </p>
        </a-card>
      </template>
    </template>
  </a-table>
  <UserModal
      :units="true"
      :show-modal="showAddUnitModal"
      :title="$t('resp-unit')"
      @add="handleAddUnit($event)"
      @close="handleCancelAdd"
  >
  </UserModal>
  <ResponseUnit
      v-if="showAddContactModal"
      :suppliers-page=true
      :contact="newContact"
      :show-modal="showAddContactModal"
      @close="handleCancelAddContact"
      @update="handleAddContact($event)">
  >
  </ResponseUnit>
  <ResponseUnit
      v-if="showEditUnitModal"
      :suppliers-page=true
      :show-modal="showEditUnitModal"
      :unit="selectedUnit"
      @update="handleEdit($event)"
      @close="handleCancelEdit"
  ></ResponseUnit>

  <DeleteFromSystem
      :show-modal="showDeleteFromSystemModal"
      @close="handleCancelDFS"
      @delete="handleOkDFS"
  >
  </DeleteFromSystem>
  <DeleteFromCompany
      :show-modal="showRemoveFromCompanyModal"
      @close="handleCancelRFC"
      @delete="handleOkRFC"
  >
  </DeleteFromCompany>
</template>

<script>
import {
  MoreOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons-vue";
import DeleteFromSystem from "@/components/Modals/DeleteFromSystem";
import DeleteFromCompany from "@/components/Modals/DeleteFromCompany";
import UserModal from "@/components/Modals/UserModal";
import ResponseUnit from "@/components/Modals/ResponseUnit";
import {notification} from "ant-design-vue";

export default {
  components: {
    MoreOutlined,
    SearchOutlined,
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    DeleteFromSystem,
    DeleteFromCompany,
    UserModal,
    ResponseUnit,
  },
  data() {
    return {
      searchValue: '',
      showAddContactModal: false,
      showAddUnitModal: false,
      showEditUnitModal: false,
      showDeleteFromSystemModal: false,
      showRemoveFromCompanyModal: false,
      user: JSON.parse(localStorage.getItem('user')),
      newContact: {
        unit_id: "",
        number: "",
        sim: "",
        name: "",
        gps: "",
        address: "",
        rn: ""
      },
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 2,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} položek`,
        onChange: this.handlePaginationChange,
        onShowSizeChange: this.handlePaginationChange,
      },
      paginationLocale: {
        items_per_page: '/ stránce',
        jump_to: 'Přejít na',
        jump_to_confirm: 'Potvrdit',
        page: '',
        prev_page: 'Předchozí stránka',
        next_page: 'Další stránka',
        prev_5: 'Předchozích 5 stránek',
        next_5: 'Dalších 5 stránek',
        prev_3: 'Předchozí 3 stránky',
        next_3: 'Další 3 stránky',
      },
      uid: '',
      selectedUnit: {},
      loading: true,
    };
  },
  computed: {
    filteredData() {
      if (!this.searchValue) {
        return this.dataSource
      }
      const filterTextLower = this.searchValue.toLowerCase();
      return this.dataSource.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    },
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    isUserAdmin() {
      return this.$store.getters["UserModule/user"].role === "Admin";
    },
    isMobileView() {
      return this.$store.getters['isMobileView']
    },
    columns() {
      if (this.isMobileView) {
        return [
          {
            title: '',
            dataIndex: 'all',
            key: 'all'
          }
        ]
      }
      return [
        {
          title: this.$t('resp-unit-id'),
          dataIndex: 'unit_id',
          key: 'unit_id',
        },
        {
          title: this.$t('number'),
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: this.$t('sim-card'),
          dataIndex: 'sim',
          key: 'sim',
        },
        {
          title: this.$t('gps-location'),
          dataIndex: 'gps',
          key: 'gps',
        },
        {
          title: this.$t('location-address'),
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: this.$t('rn-auto'),
          dataIndex: 'rn',
          key: 'rn',
        },
        {
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        },
      ]
    }
  },
  async mounted() {
    if (this.$route.params.slug) {
      this.uid = this.$route.params.slug
    }
    if (this.$route.query.pageUnit) {
      this.pagination.current = +this.$route.query.pageUnit
    }
    if(this.$route.query.pageSizeUnit) {
      this.pagination.pageSize = +this.$route.query.pageSizeUnit;
    }
    await this.getUnitsList()
  },
  methods: {
    showAddUnitHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'add_responseunit') && this.isUserAdmin) {
        this.showAddUnitModal = true
      } else if (this.isUserSuperadmin) {
        this.showAddUnitModal = true
      } else if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'add_responseunit')) {
        this.showAddContactModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    RFCHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'change_supplier')) {
        this.showRemoveFromCompanyModal= true
      } else if (this.isUserSuperadmin) {
        this.showRemoveFromCompanyModal= true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    DFSHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'delete_responseunit')) {
        this.showDeleteFromSystemModal = true
      } else if (this.isUserSuperadmin) {
        this.showDeleteFromSystemModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async getUnitsList() {
      try {
        const path = this.$route.path;
        const { current, pageSize } = this.pagination;
        await this.$router.push({ path, query: { tab: '4', pageUnit: current, pageSizeUnit: pageSize} });
        const {data} = await this.$store.dispatch('Suppliers/getListOfUnits', {
          uid: this.uid,
          page_size: this.pagination.pageSize,
          page: this.pagination.current
        })

        this.dataSource = data.results
        this.loading = false
        this.pagination.current = data.current
        this.pagination.pageSize = data.page_size
        this.pagination.total = data.count
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getUnitsList();
    },
    handleActionClick(record) {
      this.selectedUnit = record
      console.log(this.selectedUnit)
    },
    editHandler(unit) {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'change_responseunit')) {
        this.selectedUnit = unit
        this.showEditUnitModal = true
      } else if (this.isUserSuperadmin) {
        this.selectedUnit = unit
        this.showEditUnitModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    customRow(record) {
      if(!record.is_active) {
        return {
          // style: 'opacity: 0.5',
          onClick: () => {
            this.editHandler(record)
          },
        }
      } else {
        return {
          onClick: () => {
            this.editHandler(record)
            // const slug = record.key
            // this.$router.push({ name: "SupplierDetail", params: { slug } })
          },
        };
      }

    },
    async handleEdit(unit) {
      try {
        await this.$store.dispatch('Suppliers/updateUnit', {
          uid: unit.uid,
          ...unit
        })

        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.unit-updated'),
        })
        this.showEditUnitModal = false
        setTimeout(() => {
          window.location.reload()
        }, 700)
      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelEdit() {
      this.showEditUnitModal = false
      this.selectedUnit = {}
    },
    async handleOkDFS() {
      try {
        await this.$store.dispatch('Suppliers/deleteUnit', this.selectedUnit.uid)
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.user-deleted'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 700)
        this.showDeleteFromSystemModal = false
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelDFS() {
      this.showDeleteFromSystemModal  = false
    },
    async handleOkRFC() {
      try {
        await this.$store.dispatch('Suppliers/unAssignUnit', {
          uid: this.uid,
          response_units_uids: [this.selectedUnit.uid]
        })
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.user-unassigned'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 700)
        this.showRemoveFromCompanyModal = false
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelRFC() {
      this.showRemoveFromCompanyModal  = false
    },
    async handleAddContact(unit) {
      try {
        await this.$store.dispatch('Suppliers/createUnit', {
          supplier: this.uid,
          ...unit
        })
        this.showAddContactModal = false
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.contact-updated'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 700)
      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelAddContact() {

      this.showAddContactModal = false
    },
    async handleAddUnit(unit) {
      try {
        if(unit === 'create') {
          this.showAddUnitModal = false
          this.showAddContactModal = true
          return
        }

        await this.$store.dispatch('Suppliers/assignUnit', {
          uid: this.uid,
          response_units_uids: [unit]
        })
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.user-assigned'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 700)
        this.showAddUnitModal = false
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelAdd() {
      this.showAddUnitModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/inputs.scss";
@import "~@/assets/scss/components/table-styles.scss";
.tab-head {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>