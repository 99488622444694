<template>
  <a-modal v-model:visible="localShowModal" :title="title || $t('contact')" @cancel="handleCancel" width="400px">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('save')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>

    </template>
    <h2>{{contact?.company}}</h2>
    <a-row>
      <a-col :span="24">
        <label>{{ $t('first-name') }}</label>
        <a-input v-model:value="localContact.name" size="large"></a-input>
        <div v-if="isContactNameDirty">
          <div v-if="isContactNameRequired" class="error-message">{{$t('field-required')}}</div>
        </div>
      </a-col>
      <a-col v-if="userAdding" :span="24" class="mt-1">
        <label>{{ $t('nickname') }}</label>
        <a-input v-model:value="localContact.username" size="large"></a-input>
        <div v-if="isContactUserNameDirty">
          <div v-if="isContactUserNameRequired" class="error-message">{{$t('field-required')}}</div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-1">
        <label>{{ $t('email') }}</label>
        <a-input v-model:value="localContact.email" size="large"></a-input>
        <div v-if="isContactMailDirty">
          <div v-if="isContactMailRequired" class="error-message">{{$t('field-required')}}</div>
          <div v-else-if="isContactMailValid" class="error-message">{{$t('valid-email')}}</div>
        </div>
      </a-col>
      <a-col :span="24" class="mt-1">
        <label>{{ $t('number') }}</label>
        <a-input v-model:value="localContact.number" type="number" size="large"></a-input>
        <div v-if="isContactNumberDirty">
          <div v-if="isContactNumberRequired" class="error-message">{{$t('field-required')}}</div>
          <div v-else-if="isContactNumberValid" class="error-message">{{$t('phone-format')}}</div>
        </div>
      </a-col>
      <a-col v-if="false" :span="24" class="mt-1">
        <label>{{ $t('positions') }}</label>
        <a-select
            v-model:value="localContact.positions"
            style="width: 100%"
            class="select-custom-style"
            :placeholder="$t('select')"
            :options="options"
            @change="handleChange"
        ></a-select>
        <div v-if="isContactPositionsDirty">
          <div v-if="isContactPositionsRequired" class="error-message">{{$t('field-required')}}</div>
        </div>

      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
import {notification} from "ant-design-vue";
export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => {},
    },
    userAdding: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localShowModal: this.showModal,
      localContact: Object.assign({}, this.contact),
      options: []
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    contact(newVal) {
      this.localContact = Object.assign({}, newVal);
    },
  },
  async mounted() {
    try {
      console.log("modal", this.localContact)
      const {data} = await this.$store.dispatch('Subscribers/getPositionsList')
      this.options = data.results
    } catch(e) {
      notification.error({
        message: this.$t('notifications.error'),
        description: e.response.data.errors[0].detail,
      })
    }
  },
  methods: {
    handleOk() {
      this.v$.$touch()
      if(!this.userAdding) {
        if(this.v$.localContact.email.$errors.length || this.isContactNumberRequired || this.isContactNameRequired || this.isContactNumberValid) {
          return
        }
        this.localContact.positions = this.localContact?.positions?.map(p => {
          return {name: p}
        })
      } else {
        if(this.v$.localContact.email.$errors.length || this.isContactNumberRequired || this.isContactNumberValid || this.isContactNameRequired || this.isContactUserNameRequired) {
          return
        }
      }

      this.$emit('update', this.localContact)
      this.localContact = {}
      this.v$.$reset();
    },
    handleCancel() {
      this.localContact = {}
      this.v$.$reset();
      this.$emit('close')
    },
    handleChange(value) {
      console.log('test', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
:deep(.ant-select-selector) {
  height: 40.5px !important;
  border-color: #434343 !important;
  &:hover {
    border-color: var(--red-7) !important;
  }
  &:focus {
    border-color: var(--red-7) !important;
  }

}
</style>