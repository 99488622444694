<template>
  <div class="second-col-head">
    <h3 class="tab-section-title">{{ $t("documents") }}</h3>
    <a-button @click="addDocument" class="grey-btn"
      ><plus-outlined />{{ $t("add") }}</a-button
    >
    <input
      type="file"
      ref="fileInput"
      style="display: none"
      @change="handleFileChange"
    />
  </div>
  <template v-if="docs?.length">
    <a
      v-for="(d, index) in docs"
      :href="
        isUserSuperadmin || canDownload ? d.document : 'javascript:void(0)'
      "
      :key="d.document"
      download
      style="color: #ffffff"
    >
      <a-row
        class="doc-row"
        :class="{
          last: index + 1 === docs.length,
          'mt-0': index !== 0,
        }"
      >
        <a-col :span="12" class="left-doc-part">
          <div class="icon-wrap">
            <FileZipOutlined class="doc-icon" />
          </div>
          <div class="info-wrap">
            <p class="mb-0">{{ d.name }}</p>
            <p class="mb-0">
              {{ $moment(d.created_at).format("DD.MM.YYYY, HH:mm") }}
            </p>
          </div>
        </a-col>
        <a-col :span="12" class="doc-right-part">
          <a-dropdown trigger="click">
            <a-button
              shape="circle"
              class="more-btn"
              @click.stop="handleActionClick(d)"
            >
              <more-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  class="menu-item-flex"
                  @click="showDeleteDocHandler"
                  key="1"
                >
                  <delete-outlined />{{ $t("delete") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-col>
      </a-row>
    </a>
  </template>
  <h3 v-else class="mt-3">{{ $t("no-docs") }}</h3>
  <a-modal
    v-model:visible="showDeleteDocModal"
    :title="$t('sure-delete-doc')"
    @close="handleCancelDeleteDoc"
  >
    <template #footer>
      <a-button key="submit" type="primary" @click="handleDeleteDoc">{{
        $t("delete")
      }}</a-button>
      <a-button key="back" @click="handleCancelDeleteDoc">{{
        $t("cancel")
      }}</a-button>
    </template>
    <div class="delete-doc">
      <div class="icon-wrap">
        <FileZipOutlined class="doc-icon" />
      </div>
      <div class="info-wrap">
        <p class="mb-0 name">{{ this.selectedDoc?.name }}</p>
        <p class="mb-0 time">
          {{
            $moment(this.selectedDoc?.created_at).format("DD.MM.YYYY, HH:mm")
          }}
        </p>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  DeleteOutlined,
  FileZipOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { notification } from "ant-design-vue";

export default {
  components: {
    DeleteOutlined,
    FileZipOutlined,
    MoreOutlined,
    PlusOutlined,
  },
  data() {
    return {
      selectedDoc: null,
      showDeleteDocModal: false,
    };
  },
  props: {
    userTypeFormData: {
      type: String,
      default: "",
    },
    userType: {
      type: String,
      default: "",
    },
    uid: {
      type: String,
      default: "",
    },
    docs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isUserSuperadmin() {
      return this.$store.getters["UserModule/user"].role === "Superadmin";
    },
    canDownload() {
      return this.$store.getters["UserModule/user"]?.user_permissions?.find(
        (p) => p.codename === "export_document"
      );
    },
  },
  methods: {
    async handleDeleteDoc() {
      try {
        await this.$store.dispatch(
          `${this.userType}/deleteDocument`,
          this.selectedDoc.uid
        );
        this.showDeleteDocModal = false;
        this.selectedDoc = null;
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.doc-deleted"),
        });
        setTimeout(() => {
          window.location.reload();
        }, 700);
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    handleCancelDeleteDoc() {
      this.showDeleteDocModal = false;
    },
    showDeleteDocHandler() {
      if (
        this.$store.getters["UserModule/user"]?.user_permissions?.find(
          (p) => p.codename === "delete_document"
        )
      ) {
        this.showDeleteDocModal = true;
      } else if (this.isUserSuperadmin) {
        this.showDeleteDocModal = true;
      } else {
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("no-permission"),
        });
      }
    },
    handleActionClick(doc) {
      this.selectedDoc = doc;
    },
    addDocument() {
      if (
        this.$store.getters["UserModule/user"]?.user_permissions?.find(
          (p) => p.codename === "add_document"
        )
      ) {
        this.$refs.fileInput.click();
      } else if (this.isUserSuperadmin) {
        this.$refs.fileInput.click();
      } else {
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("no-permission"),
        });
      }
    },
    async handleFileChange(event) {
      try {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append("document", file);
        formData.append("name", file.name);
        formData.append(this.userTypeFormData, this.uid);
        // Now you can send the formData to the server using AJAX or other methods
        await this.$store.dispatch(`${this.userType}/createDocument`, formData);
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.doc-created"),
        });
        setTimeout(() => {
          window.location.reload()
        }, 700)
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
    @import "~@/assets/scss/components/first-tab.scss";
</style>
