<template>
  <a-modal v-model:visible="localShowModal" @cancel="handleCancel" width="700px">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('modal-actions.update')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
    </template>
    <h2 class="px-1">{{$t('resp-unit')}}</h2>
    <a-row class="data-container">
      <a-col :xs="24" :md="12">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t('resp-unit-id-full') }}</label>
                <a-input v-model:value="localUnit.unit_id" size="large"></a-input>
                <div v-if="isUnitIdDirty">
                  <div v-if="isUnitIdRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class=" inp-wrap">
                <label>{{ $t('nickname') }}</label>
                <a-input v-model:value="localUnit.name" size="large"></a-input>
                <div v-if="isUnitLoginDirty">
                  <div v-if="isUnitLoginRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div v-if="!suppliersPage" class="data mt-1 ">
              <div class="inp-wrap">
                <label>{{ $t('supplier') }}</label>
                <a-select
                    v-model:value="localUnit.supplier"
                    style="width: 100%"
                    class="select-custom-style"
                    :placeholder="$t('select')"
                    :options="options"
                ></a-select>
                <div v-if="isUnitSuppliersDirty">
                  <div v-if="isUnitSuppliersRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t('gps-location') }}</label>
                <a-input v-model:value="localUnit.gps" size="large"></a-input>
                <div v-if="isUnitGpsDirty">
                  <div v-if="isUnitGpsRequired" class="error-message">{{$t('field-required')}}</div>
                  <div v-else-if="isUnitGpsValid" class="error-message">{{$t('gps-format')}}</div>
                </div>
              </div>
            </div>
          </a-col>

        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="second">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t('number') }}</label>
                <a-input v-model:value="localUnit.number" type="number" size="large"></a-input>
                <div v-if="isUnitNumberDirty">
                  <div v-if="isUnitNumberRequired" class="error-message">{{$t('field-required')}}</div>
                  <div v-else-if="isUnitNumberValid" class="error-message">{{$t('phone-format')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t('sim-card') }}</label>
                <a-input v-model:value="localUnit.sim" size="large"></a-input>
                <div v-if="isUnitSimDirty">
                  <div v-if="isUnitSimRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t('rn-auto') }}</label>
                <a-input v-model:value="localUnit.rn" size="large"></a-input>
                <div v-if="isUnitRnDirty">
                  <div v-if="isUnitRnRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t('location-address') }}</label>
                <a-input v-model:value="localUnit.address" size="large"></a-input>
                <div v-if="isUnitAddressDirty">
                  <div v-if="isUnitAddressRequired" class="error-message">{{$t('field-required')}}</div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import {useVuelidate} from "@vuelidate/core/dist/index.mjs";
import {notification} from "ant-design-vue";
export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: Object,
      default: () => {},
    },
    suppliersPage: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data() {
    return {
      localShowModal: this.showModal,
      localUnit: Object.assign({}, this.unit),
      options: []
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    unit(newVal) {
      this.localUnit = Object.assign({}, newVal);
    },
  },
  async mounted() {
    try {
      const {data} = await this.$store.dispatch('Suppliers/getSuppliersListForModal')
      this.options = data.results.map(o => {
        return {
          label: o.name,
          value: o.uid
        }
      })
    } catch(e) {
      notification.error({
        message: this.$t('notifications.error'),
        description: e.response.data.errors[0].detail,
      })
    }


  },
  methods: {
    handleOk() {
      this.v$.$touch();
      if (this.suppliersPage) {
        if(this.isUnitIdRequired
            || this.isUnitLoginRequired
            || this.isUnitNumberRequired
            || this.isUnitNumberValid
            || this.isUnitSimRequired
            || this.isUnitAddressRequired
            || this.isUnitGpsRequired
            || this.isUnitGpsValid
        ) {
          return
        }
      } else {
        if(this.isUnitIdRequired
            || this.isUnitSuppliersRequired
            || this.isUnitLoginRequired
            || this.isUnitNumberRequired
            || this.isUnitNumberValid
            || this.isUnitSimRequired
            || this.isUnitAddressRequired
            || this.isUnitGpsRequired
            || this.isUnitGpsValid
        ) {
          return
        }
      }


      this.$emit('update', this.localUnit)
      this.localUnit = {}
      this.v$.$reset();
    },
    handleCancel() {
      this.localUnit = {}
      this.v$.$reset();
      this.$emit('close')
    },
  }


}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
@import "~@/assets/scss/components/first-tab.scss";
:deep(.ant-select-selector) {
  height: 40.5px !important;
  border-color: #434343 !important;
  &:hover {
    border-color: var(--red-7) !important;
  }
  &:focus {
    border-color: var(--red-7) !important;
  }

}
</style>