<template>
  <div class="subs-wrap" v-if="!$route.params.slug">
    <div class="mb-2 subs-head">
      <a-input v-model:value="searchValue" class="search-input" :placeholder="$t('search')">
        <template #prefix>
          <search-outlined class="search-icon" />
        </template>
      </a-input>
      <div class="btns-wrap">
        <a-button @click="addSubscriberHandler" size="large" type="primary"><plus-outlined />{{ $t('add') }}</a-button>
        <a-button @click="exportData" size="large">{{ $t('export') }}</a-button>
      </div>
    </div>

    <a-table
        :columns="columns"
        :data-source="filteredData"
        :pagination="{ ...pagination, locale: paginationLocale }"
        :customRow="customRow"
        :show-header="!isMobileView"
        :loading="loading"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <a-dropdown trigger="click">
            <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
              <more-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="deleteHandler" key="1"><delete-outlined /> {{ $t('delete') }}</a-menu-item>
              </a-menu>
            </template>

          </a-dropdown>
        </template>
        <template v-if="column.key === 'all'">
          <a-card :title="$t('user')" class="table-card">
            <p class="user-card-row">
              <span>
                {{$t('subscriber')}}
              </span>
              <span>{{record.name}}</span>
            </p>
            <p class="user-card-row">
              <span>
                IČO
              </span>
              <span>
                {{record.ico}}
              </span>
            </p>
            <p class="user-card-row">
              <span>
                DIČ
              </span>
              <span>
                {{record.dic}}
              </span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('residence')}}
              </span>
              <span>{{record.address}}</span>
            </p>

            <p class="user-card-row">
              <span>
                {{$t('actions')}}
              </span>
              <span>
                <a-dropdown trigger="click">
                  <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
                    <more-outlined />
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="deleteHandler" key="1"><delete-outlined /> {{ $t('delete') }}</a-menu-item>
                    </a-menu>
                  </template>

                </a-dropdown>
              </span>
            </p>
          </a-card>
        </template>
      </template>
    </a-table>
    <CompanyModal
        :company="subscriber"
        :userTypePrefix="'sub'"
        :show-modal="showAddModal"
        @close="handleCancel"
        @update="handleOk($event)"
    ></CompanyModal>
    <DeleteSModal
        :title="$t('modal-titles.delete-sub')"
        :company="selectedSub"
        :show-modal="showDeleteSubModal"
        @close="handleCancelDelete"
        @delete="handleOkDelete"
    ></DeleteSModal>
  </div>
  <router-view v-else />

</template>

<script>
import {
  MoreOutlined,
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons-vue";
import CompanyModal from "@/components/Modals/Company";
import DeleteSModal from "@/components/Modals/DeleteSModal";
import {notification} from "ant-design-vue";

export default {
  components: {
    MoreOutlined,
    SearchOutlined,
    DeleteOutlined,
    PlusOutlined,
    CompanyModal,
    DeleteSModal
  },
  data() {
    return {
      showAddModal: false,
      searchValue: '',
      user: JSON.parse(localStorage.getItem('user')),
      showAddSubModal: false,
      showDeleteSubModal: false,
      loading: true,
      subscriber: {
        mailing_address_input: false,
        ico: '',
        dic: '',
        name: '',
        street: '',
        zip: '',
        district: '',
        account: '',
        mailing_address: {
          street: '',
          zip: '',
          district: '',
        }
      },
      dataSource: [],
      permissions: null,
      paginationLocale: {
        items_per_page: '/ stránce',
        jump_to: 'Přejít na',
        jump_to_confirm: 'Potvrdit',
        page: '',
        prev_page: 'Předchozí stránka',
        next_page: 'Další stránka',
        prev_5: 'Předchozích 5 stránek',
        next_5: 'Dalších 5 stránek',
        prev_3: 'Předchozí 3 stránky',
        next_3: 'Další 3 stránky',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 2,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} položek`,
        onChange: this.handlePaginationChange,
        onShowSizeChange: this.handlePaginationChange,
      },
      selectedSub: {}
    };
  },
  computed: {
    filteredData() {
      if (!this.searchValue) {
        return this.dataSource
      }
      const filterTextLower = this.searchValue.toLowerCase();
      return this.dataSource.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    },
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    isMobileView() {
      return this.$store.getters['isMobileView']
    },
    columns() {
      if (this.isMobileView) {
        return [
          {
            title: '',
            dataIndex: 'all',
            key: 'all'
          }
        ]
      }
      return [
        {
          title: this.$t('subscriber'),
          dataIndex: 'name',
          key: 'name',
          width: 200,
        },
        {
          title: 'IČO',
          dataIndex: 'ico',
          key: 'ico',
        },
        {
          title: 'DIČ',
          dataIndex: 'dic',
          key: 'dic',
        },
        {
          title: this.$t('residence'),
          dataIndex: 'address',
          key: 'address',
        },
        {
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        },
      ]
    }
  },
  async mounted() {
    if (this.$route.query.page) {
      this.pagination.current = +this.$route.query.page
    }
    if(this.$route.query.pageSize) {
      this.pagination.pageSize = +this.$route.query.pageSize;
    }
    await this.getSubsList()

  },
  methods: {
    addSubscriberHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'add_subscriber')) {
        this.showAddModal = true
      } else if (this.isUserSuperadmin) {
        this.showAddModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async getSubsList() {
      try {
        const path = this.$route.path;
        const { current, pageSize } = this.pagination;
        if(!this.$route.query.pageContact) {
          await this.$router.push({ path, query: { page: current, pageSize: pageSize} });
        }

        const {data} = await this.$store.dispatch('Subscribers/getSubsList', {
          page_size: this.pagination.pageSize,
          page: this.pagination.current
        })
        this.dataSource = data.results
        this.loading = false
        this.pagination.current = data.current
        this.pagination.pageSize = data.page_size
        this.pagination.total = data.count
      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getSubsList();
    },
    handleActionClick(record) {
      this.selectedSub = record
    },
    async handleOk(companyData) {
      try {

        await this.$store.dispatch('Subscribers/createSubscriber', companyData)
        this.showAddModal = false
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.sub-created'),
        })
        setTimeout(() => {
          window.location.reload()
        }, 700)

      } catch(e) {
        console.log(e)
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    handleCancel() {
      this.showAddModal = false
    },
    deleteHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'delete_subscriber')) {
        this.showDeleteSubModal = true
      } else if (this.isUserSuperadmin) {
        this.showDeleteSubModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async handleOkDelete() {
      try {
        await this.$store.dispatch('Subscribers/deleteSubscriber', this.selectedSub.uid)
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.sub-deleted'),
        })
        this.showDeleteSubModal = false
        setTimeout(() => {
          window.location.reload()
        }, 700)
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelDelete() {
      this.showDeleteSubModal = false
    },
    customRow(record) {
      return {
        onClick: () => {
          const slug = record.uid
          this.$router.push({ name: "SubscriberDetail", params: { slug } })
        },
      };
    },
    async exportData() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'export_subscriber')) {
        try {
          const {data} = await this.$store.dispatch('Subscribers/exportData', {
            subscribers_uids: this.dataSource.map(s => s.uid)
          })

          window.open(data?.url, "_blank");
        } catch (e) {
          notification.error({
            message: this.$t('notifications.error'),
            description: e.response.data.errors[0].detail,
          })
        }
      } else if (this.isUserSuperadmin) {
        try {
          const {data} = await this.$store.dispatch('Subscribers/exportData', {
            subscribers_uids: this.dataSource.map(s => s.uid)
          })
          window.open(data?.url, "_blank");
        } catch (e) {
          notification.error({
            message: this.$t('notifications.error'),
            description: e.response.data.errors[0].detail,
          })
        }
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/inputs.scss";
@import "~@/assets/scss/components/table-styles.scss";

.subs-wrap {
  padding: 3rem 40px 0;
  .subs-head {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
}

</style>
