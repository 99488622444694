<template>
  <a-modal
    v-model:visible="localShowModal"
    @cancel="handleCancel"
    width="700px"
  >
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{
        $t("modal-actions.update")
      }}</a-button>
      <a-button key="back" @click="handleCancel">{{ $t("cancel") }}</a-button>
    </template>
    <h2 class="px-1">{{ $t("user") }}</h2>
    <a-row class="data-container">
      <a-col :xs="24" :md="12">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t("first-name") }}</label>
                <a-input v-model:value="user.name" size="large"></a-input>
<!--                <div v-if="isNameDirty">-->
<!--                  <div v-if="isNameRequired" class="error-message">-->
<!--                    {{ $t("field-required") }}-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t("nickname") }}</label>
                <a-input v-model:value="user.username" size="large"></a-input>
                <div v-if="isNickDirty">
                  <div v-if="isNickRequired" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                </div>
              </div>
            </div>
              <div class="data mt-1">
                <div class="inp-wrap">
                  <label>{{ $t("role") }}</label>
                  <a-select
                    readonly
                    v-model:value="user.role"
                    style="width: 100%"
                    class="select-custom-style"
                    :placeholder="$t('select')"
                    :options="options"
                  ></a-select>
                  <div v-if="isRolesDirty">
                    <div v-if="isRolesRequired" class="error-message">
                      {{ $t("field-required") }}
                    </div>
                  </div>
                </div>
              </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="second">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t("number") }}</label>
                <a-input
                  v-model:value="user.number"
                  type="number"
                  size="large"
                ></a-input>
                <div v-if="isNumberDirty && isNumberValid && user?.number?.length > 0" class="error-message">
                  {{ $t("phone-format") }}
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t("email") }}</label>
                <a-input
                  v-model:value="user.email"
                  aria-autocomplete="none"
                  autocomplete="off"
                  size="large"
                ></a-input>
                <div v-if="isUserEmailDirty">
                  <div v-if="isUserEmailRequired" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                  <div v-else-if="isUserEmailValid" class="error-message">
                    {{ $t("valid-email") }}
                  </div>
                </div>
              </div>
            </div>
              <div v-if="showPassword || !this.isEdit" class="data mt-1">
                <div class="inp-wrap">
                  <label>{{ $t("password") }}</label>
                  <a-input-password
                    v-model:value="user.password"
                    :placeholder="$t(`${isEdit ? 'edit' : 'set'}-a-password`)"
                    aria-autocomplete="none"
                    autocomplete="new-password"
                    size="large"
                    :style="{
                      borderColor: isPasswordFocused ? '#e84749' : '#434343',
                      'box-shadow': 'none',
                    }"
                    @focus="isPasswordFocused = true"
                    @blur="isPasswordFocused = false"
                  ></a-input-password>
                  <div v-if="isUserPasswordDirty">
                    <div v-if="isUserPasswordRequired && !isEdit" class="error-message">
                      {{ $t("field-required") }}
                    </div>
                    <div v-else-if="isUserPasswordValid && user?.password?.length" class="error-message">
                      {{ $t("valid-password") }}
                    </div>
                  </div>
                </div>
              </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24" class="px-1">
        <div v-if="user.role !== 'Superadmin'" class="data mt-1">
          <div class="inp-wrap">
            <div class="tree-header">
              <label>{{ $t("permissions") }}</label>
            </div>
            <a-table
              :columns="columns"
              :data-source="dataSource"
              :pagination="false"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'name'">
                  {{ $t(record.name) }}
                </template>
                <template
                  v-if="column.key === 'view' && record.view !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                      v-model:checked="record.view"
                      @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                  v-if="column.key === 'all' && record.all !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                      v-model:checked="record.all"
                      @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                  v-if="column.key === 'change' && record.change !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                      v-model:checked="record.change"
                      @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                  v-if="column.key === 'add' && record.add !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                      v-model:checked="record.add"
                      @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                  v-if="column.key === 'delete' && record.delete !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                      v-model:checked="record.delete"
                      @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                  v-if="column.key === 'export' && record.export !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                      v-model:checked="record.export"
                      @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { useVuelidate } from "@vuelidate/core/dist/index.mjs";
import parsePhoneNumberFromString from 'libphonenumber-js';

export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    userObject: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      localShowModal: this.showModal,
      user: Object.assign({}, this.userObject),
      matrixData: [],
      matchedPermissions: [],
      currentPermissions: [],
      options: [
        {
          label: "Superadmin",
          value: "Superadmin",
        },
        {
          label: "Admin",
          value: "Admin",
        },
      ],
      selectAll: false,
      dataSource: [
        {
          name: this.$t("user"),
          view: false,
          all: false,
          change: false,
          add: false,
          delete: false,
          export: false,
          model: "user",
        },
        {
          name: this.$t("subscribers"),
          view: false,
          all: false,
          change: false,
          add: false,
          delete: false,
          export: false,
          model: "subscriber",
        },
        {
          name: this.$t("subs-contacts"),
          all: false,
          change: false,
          add: false,
          delete: false,
          model: "contact_subscribers",
        },
        {
          name: this.$t("subs-docs"),
          all: false,
          add: false,
          delete: false,
          export: false,
          model: "document",
        },
        {
          name: this.$t("suppliers"),
          view: false,
          all: false,
          change: false,
          add: false,
          delete: false,
          export: false,
          model: "supplier",
        },
        {
          name: this.$t("sups-contacts"),
          all: false,
          change: false,
          add: false,
          delete: false,
          model: "contact_suppliers",
        },
        {
          name: this.$t("response-units"),
          view: false,
          all: false,
          change: false,
          add: false,
          delete: false,
          export: false,
          model: "responseunit",
        },
      ],
      columns: [
        { title: this.$t("perm-name"), dataIndex: "name", key: "name" },
        {
          title: this.$t("allow-all"),
          dataIndex: "all",
          key: "all",
          type: "checkbox",
        },
        {
          title: this.$t("section-access"),
          dataIndex: "view",
          key: "view",
          type: "checkbox",
        },
        {
          title: this.$t("edit-perm"),
          dataIndex: "change",
          key: "change",
          type: "checkbox",
        },
        {
          title: this.$t("add"),
          dataIndex: "add",
          key: "add",
          type: "checkbox",
        },
        {
          title: this.$t("remove"),
          dataIndex: "delete",
          key: "delete",
          type: "checkbox",
        },
        {
          title: this.$t("export-perm"),
          dataIndex: "export",
          key: "export",
          type: "checkbox",
        },
      ],
      isPasswordFocused: false,
    };
  },
  computed: {
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    showPassword() {
      return this.isEdit && this.user?.role !== 'Superadmin' && this.isUserSuperadmin
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    userObject(newVal) {
      this.user = Object.assign({}, newVal);
    },
  },
  async mounted() {
    const { data } = await this.$store.dispatch(
      "UserModule/getListOfPermissions"
    );
    this.matrixData = data;
    if (this.userObject) {
      this.dataSource = this.userObject?.user_permissions_table;
      this.matchedPermissions = this.userObject?.user_permissions;
      // console.log("this.matchedPermissions", this.matchedPermissions);
      // this.currentPermissions = new Set()
      // this.currentPermissions= this.userObject?.user_permissions.forEach(i => this.currentPermissions.add(i))
      // console.log(this.currentPermissions)
    }
    this.user.number = this.user.phone; // Validation inconsistency with EmergencyModal and EmergencyModal2
  },
  methods: {
    onCheckboxChange(column, record) {
      const uniqueArr = this.matchedPermissions.filter(
        (item, index) => this.matchedPermissions.indexOf(item) === index
      );
      this.matchedPermissions = [...uniqueArr];
      if (column.key === "all") {
        // Update all checkboxes in the current row with the value of the "All" checkbox
        for (const key in record) {
          if (key !== "key" && key !== "name" && key !== "model") {
            record[key] = record[column.key];
          }
        }
      } else {
        // If any checkbox other than "all" is unchecked, uncheck the "all" checkbox
        if (!record[column.key]) {
          record.all = false;
        } else {
          // Check if all other checkboxes are checked; if so, check the "all" checkbox
          let allChecked = true;
          for (const key in record) {
            if (key !== "key" && key !== "name" && key !== "all" && key !== "model") {
              if (!record[key]) {
                allChecked = false;
                break;
              }
            }
          }
          record.all = allChecked;
        }
      }
      const matchingMatrixData = Object.keys(record).reduce((acc, key) => {
        if (
          key !== "key" &&
          key !== "name" &&
          key !== "model" &&
          record[key] === true
        ) {
          const filteredMatrixData = this.matrixData.filter((item) => {
            if (
              item.model === record.model &&
              item.codename === `${key}_${record.model}`
            ) {
              return item;
            }
          });
          acc.push(...filteredMatrixData);
        }
        return acc;
      }, []);
      matchingMatrixData.forEach((item) => {
        this.matchedPermissions.push(item.id);
      });
      Object.keys(record).forEach((key) => {
        if (
          key !== "key" &&
          key !== "name" &&
          key !== "model" &&
          key !== "ids" &&
          record[key] === false
        ) {
          this.matrixData.forEach((item) => {
            if (
              item.model === record.model &&
              item.codename === `${key}_${record.model}`
            ) {
              const index = this.matchedPermissions.indexOf(item.id);
              if (index > -1) {
                this.matchedPermissions.splice(index, 1);
              }
            }
          });
        }
      });
      this.user.user_permissions = this.matchedPermissions;
    },
    handleOk() {
      this.v$.$touch();
      if (this.isUserEmailRequired || this.isUserEmailValid || this.isNickRequired || this.isRolesRequired) {
        return;
      }

      if ((!this.isEdit || (this.isEdit && this.user?.password && this.user?.password?.length > 0)) &&
          (this.isUserPasswordRequired || this.isUserPasswordValid)) {
        return;
      }
      if(this.user?.number?.length > 0 && this.isNumberValid) {
        return
      }

      // this.user.user_permissions = this.user.user_permissions
      if(!this.user?.password?.length) {
        delete this.user?.password
      }
      if(!this.user?.number?.length) {
        delete this.user?.number
        delete this.user?.phone
        this.user.phone = null // Validation inconsistency with EmergencyModal and EmergencyModal2
      } else {
        this.user.number = parsePhoneNumberFromString(this.user.number, 'CZ').nationalNumber 
        this.user.phone = this.user.number // Validation inconsistency with EmergencyModal and EmergencyModal2
      }
      this.$emit("update", this.user);
      this.v$.$reset();
    },
    handleCancel() {
      this.user = {};
      this.selectAll = false;
      this.v$.$reset();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
@import "~@/assets/scss/components/first-tab.scss";
:deep(.ant-select-selector) {
  height: 40.5px !important;
  border-color: #434343 !important;
  overflow: hidden !important;

  &:hover {
    border-color: var(--red-7) !important;
  }
  &:focus {
    border-color: var(--red-7) !important;
  }
}
.tree-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkbox-wrap {
  display: flex;
  justify-content: center;
}
:deep(.ant-table) {
  @media (max-width: 1150px) {
    overflow-x: scroll !important;
  }
}
</style>
